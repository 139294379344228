import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { productsService } from '../../../api/service.products';
import { publicSiteService } from '../../../api/service.publicsite';
import router from '../../../routing/router';
import routes from '../../../routing/routes';
import { productImageBasePath, productImagePlaceholder } from '../../../utils/const';
import { formatPrice, formatWeight, shorten } from '../../../utils/format';
import { cart } from '../../app';
import { DialogParams } from '../../elements/bp-dialog';

class ShopIndex {

  readonly dialog: Observable<DialogParams | null>;
  readonly announcementContent: Observable<string | null>;
  readonly lastUpdated: Observable<string>;
  readonly title: Observable<string>;
  readonly products: ObservableArray<any>;
  readonly category: string;
  readonly descriptionHtml: Observable<string | null>;
  readonly descriptionName: Observable<string | null>;
  readonly descriptionImages: ObservableArray;

  constructor(params: any) {

    this.dialog = ko.observable(null);
    this.announcementContent = ko.observable(null);
    this.lastUpdated = ko.observable('');
    this.title = ko.observable('Shop online at Woofles!');
    this.products = ko.observableArray();
    this.category = params.category;
    this.descriptionHtml = ko.observable(null);
    this.descriptionName = ko.observable(null);
    this.descriptionImages = ko.observableArray();

    const categoryIds: number[] = [];
    const showBulk = this.category == 'woofles-bulk';

    switch (this.category) {
      case "homeopathics":
        categoryIds.push(11, 12);
        break;

      case "other":
        categoryIds.push(15, 16, 17, 18, 21, 22);
        break;

      default:
        categoryIds.push(8, 9);
        break;
    }

    publicSiteService.getContentByPageName('generic', 'covid-info')
      .then(result => {
        this.announcementContent(result.html);
        this.lastUpdated(result.lastUpdate);
      });

    productsService.getProducts()
      .then(results => {
        const productMap = results
          .filter(p => categoryIds.includes(p.productCategoryId, 0))
          .filter(p => (showBulk && p.isBulk) || (!showBulk && !p.isBulk))
          .map(p => {

            var productIcons = [];

            if (p.isDog) { productIcons.push({ icon: 'icon/dog', title: 'Suitable for dogs' }); }
            if (p.isCat) { productIcons.push({ icon: 'icon/cat', title: 'Suitable for cats' }); }
            if (p.isLoyaltyItem) { productIcons.push({ icon: 'icon/loyalty', title: 'This item will count towards your loyalty rewards.' }); }

            const isFocussed = ko.observable(false);

            const formattedImages = p.images.map(c => {
              const url = `${productImageBasePath}/${c}`;
              return url;
            });

            return {
              ...p,
              formattedPrice: formatPrice(p.retailRRP),
              formattedWeight: formatWeight(p.unitWeight),
              icons: ko.observableArray(productIcons),
              formattedImageUrl: p.imageUrl == null ? `${productImageBasePath}/${productImagePlaceholder}` : `${productImageBasePath}/${p.imageUrl}`,
              visible: p.packagingDetails != '',
              unitPrice: p.retailRRP,
              isFocussed: isFocussed,
              formattedDescription: shorten(p.description, 150, '&hellip;', true),
              isOnSale: p.salePrice > 0,
              formattedSalePrice: `SPECIAL: ${formatPrice(p.salePrice)}`,
              formattedImages: formattedImages
            }
          });

        this.products(productMap);
      });

  }




  goto = {
    details: (productId: number): void => router.goto(routes.shop.interpolate({})),

    shopWooflesRange: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'woofles-range' })),
    shopWooflesBulk: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'woofles-bulk' })),
    shopHomeopathics: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'homeopathics' })),
    shopOther: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'other' })),
    shopFreight: (): void => router.goto(routes.shopByCategory.interpolate({ category: 'freight-costs' })),


    //login: (): void => router.goto(routes.login.interpolate({})),
    //register: (): void => router.goto(routes.register.interpolate({}))
  }


  actions = {
    showDescription: (name: string, description: string, images: string[]): void => {
      this.descriptionName(name);
      this.descriptionHtml(description);
      this.descriptionImages(images);
    },

    closeDescription: (): void => {
      this.descriptionName(null);
      this.descriptionHtml(null);
      this.descriptionImages(null);
    },

    updateCart: (productId: number, quantity: number): number => {
      const product = cart.findInCart(productId);

      if (null == product) {
        return 0;
      }

      return cart.update(productId, this.products(), quantity);
    },

    incrementCart: (productId: number): number => {
      return cart.add(productId, this.products(), 1);
    },

    decrementCart: (productId: number): number => {
      return cart.decrease(productId, 1);
    }

  }
}

export default {
  name: 'wp-shop-index',
  viewModel: ShopIndex,
  template: require('./index.html')
};
